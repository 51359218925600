/* Основной контейнер */
.webapp-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: #121212; /* Тёмный фон */
    font-family: Arial, sans-serif;
    text-align: center;
    color: #ffffff;
}

/* Контент страницы */
.content {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
}

/* Навигационное меню */
.nav-menu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    background: #1e1e1e; /* Тёмный фон */
    border-top: 1px solid #333;
    padding: 10px 0;
    box-shadow: 0 -2px 5px rgba(255, 255, 255, 0.1);
}

/* Кнопки навигации */
.nav-button {
    flex: 1;
    padding: 10px 0;
    font-size: 16px;
    font-weight: bold;
    color: #bbb;
    background: none;
    border: none;
    cursor: pointer;
    transition: color 0.3s, background-color 0.3s;
}

/* Активная кнопка */
.nav-button.active {
    color: #ffffff;
    background: #007bff;
    border-radius: 8px;
}

/* Наведение на кнопку */
.nav-button:hover {
    background: #0056b3;
    color: #ffffff;
}

/* Аватарка канала */
.channel-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
    object-fit: cover;
}

/* Кнопка добавления канала */
.add-channel-button {
    display: block;
    margin: 20px auto;
    padding: 10px 20px;
    font-size: 16px;
    color: white;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.add-channel-button:hover {
    background-color: #0056b3;
}

/* Темный стиль для списка каналов */
.channel-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background 0.3s;
}

.channel-card:hover {
    background: #292929;
}

/* Контейнер информации о канале */
.channel-info {
    flex-grow: 1;
    text-align: left;
    padding-left: 15px;
}

/* Название канала */
.channel-info h3 {
    margin: 0;
    font-size: 18px;
    color: #ffffff;
}

/* Количество подписчиков */
.channel-subscribers {
    font-size: 14px;
    color: #aaaaaa;
    text-align: right;
}
