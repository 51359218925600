/* Основные стили */
body {
    background-color: #121212;
    color: #ffffff;
    font-family: Arial, sans-serif;
}

/* Контейнер списка каналов */
.channels-list-container {
    padding: 20px;
    text-align: center;
}

/* Заголовок */
.channels-list-container h2 {
    color: #ffffff;
}

/* Карточка канала в виде полоски */
.channel-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: #1e1e1e;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(255, 255, 255, 0.1);
    cursor: pointer;
    transition: background 0.3s;
}

.channel-card:hover {
    background: #292929;
}

/* Аватарка канала */
.channel-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}

/* Контейнер информации */
.channel-info {
    flex-grow: 1;
    text-align: left;
    padding-left: 15px;
}

/* Название канала */
.channel-info h3 {
    margin: 0;
    font-size: 18px;
    color: #ffffff;
}

/* Количество подписчиков */
.channel-subscribers {
    font-size: 14px;
    color: #aaaaaa;
    text-align: right;
}

/* Стиль выбранного канала (форма selectedChannel) */
.channel-settings {
    padding: 20px;
    background: #1e1e1e; /* Тёмный фон */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1);
    text-align: center;
    color: #ffffff;
}

/* Заголовок формы */
.channel-settings h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #ffffff;
}

/* Кнопки в форме */
.channel-settings button {
    margin: 10px 5px;
    padding: 10px 20px;
    font-size: 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}
.channel-settings {
    padding: 20px;
    background: #1e1e1e; /* Темный фон */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    text-align: center;
    color: #ffffff;
}

.channel-settings h2 {
    color: #ffffff; /* Белый текст для заголовка */
}

.channel-settings button {
    margin: 10px 5px;
    padding: 10px 20px;
    font-size: 16px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.channel-settings button:hover {
    background: #0056b3;
}
