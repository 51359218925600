body, html {
  margin: 0;
  padding: 0;
  overflow: hidden; /* This will disable scrolling */
  height: 100%; /* This ensures your page takes up full viewport height */
}

.App {
  text-align: center;
}

.background {
  height: 100vh;
  background-image: url('img/image1.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center children horizontally */
  justify-content: center; /* Center children vertically */
  color: white;
}


.background-blur {
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  overflow: hidden;
  font-family: 'Roboto', sans-serif; /* Современный шрифт */
}

.background-blur::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: url('img/image1.jpg');
  background-size: cover;
  background-position: center;
  filter: blur(15px); /* Применение эффекта размытия */
  z-index: -1; /* Помещение фона за основной контент */
}

.app-header {
  font-size: 3.125rem;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 52px;
  margin: 0 0 26px;
  color: #fff;
  text-shadow: 0 0 27px #000000;
}

.app-subheader {
  font-size: 1.563rem;
  line-height: 38px;
  text-shadow: 0 0 27px rgba(0, 0, 0, .9);
  margin: 0
}

.button-group {
  display: flex;
  flex-direction: column; /* Stack buttons vertically */
  align-items: center; /* Center buttons horizontally */
  margin-top: 20px; /* Spacing from the header */
}

.button {
  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  font-size: 1.125rem;
  display: block;
  text-align: center;
  margin: 33px auto 0;
  text-transform: uppercase;
  padding: 20px;
  background: #ff9000;
  border: none;
  font-weight: 700;
  cursor: pointer;
  min-height: 55px;
  color: #000;
  box-sizing: border-box;
  text-decoration: none;
  min-width: 250px;
  transition: box-shadow 0.3s ease;
  transition: background-color 0.3s ease;
}

.button-tagio {
  background-color: #5c6bc0;
  color: white;
}

.button-teletarget {
  background-color: #42a5f5;
  color: white;
}

.button:hover {
  background-color: #e69500; /* Optional: darker shade on hover */
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); /* Тень при наведении */
}

.app-subheader a {
  color: #1a53ff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
}

.app-subheader a:hover {
  color: #ff4b2b; /* Цвет текста при наведении */
  text-decoration: underline; /* Подчеркивание при наведении */
}

