/* Основной контейнер для списка каналов */
.my-channels-container {
    padding: 20px;
    background-color: #121212; /* Темный фон */
    color: #ffffff; /* Белый текст */
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

/* Заголовок */
.my-channels-container h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #f0f0f0;
}

/* Список каналов */
.channel-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Карточка канала */
.channel-card {
    background: #1e1e1e; /* Темный фон карточки */
    padding: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: transform 0.2s;
    cursor: pointer;
}

.channel-card:hover {
    transform: translateY(-2px);
    background-color: #2c2c2c; /* Светлее при наведении */
}

/* Аватар канала */
.channel-avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 15px;
}

/* Информация о канале */
.channel-info {
    flex: 1;
    color: #e0e0e0;
}

.channel-info h3 {
    margin: 0;
    font-size: 18px;
    color: #f0f0f0;
}

.channel-info p {
    margin: 5px 0 0;
    font-size: 14px;
    color: #c0c0c0;
}

/* Кнопки */
.add-channel-button,
.channel-settings button {
    display: inline-block;
    margin: 10px 5px;
    padding: 10px 20px;
    font-size: 16px;
    color: #ffffff;
    background: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
}

.add-channel-button:hover,
.channel-settings button:hover {
    background: #0056b3;
}

/* Форма добавления канала */
.add-channel-form {
    background: #1e1e1e; /* Темный фон формы */
    padding: 15px;
    margin-top: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    gap: 10px;
}

/* Поля ввода */
.add-channel-form input,
.channel-settings input {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #333333;
    border-radius: 4px;
    background: #121212;
    color: #ffffff;
    transition: border-color 0.3s;
}

.add-channel-form input:focus,
.channel-settings input:focus {
    border-color: #007bff;
    outline: none;
}

/* Текст ошибки */
.error-message {
    color: #ff4d4d;
    font-size: 14px;
    text-align: center;
}

/* Поле для обновления ссылки */
.channel-settings input {
    margin: 10px 0;
    width: 100%;
}


/* Кнопка "Сохранить" */
.add-channel-form button {
    padding: 10px 15px;
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    background: #007bff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.add-channel-form button:hover {
    background: #0056b3;
}